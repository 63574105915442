/**
 * First we will load all of this project's JavaScript dependencies which
 * includes React and other helpers. It's a great starting point while
 * building robust, powerful web applications using React + Laravel.
 */

import './bootstrap.js';

import Alpine from 'alpinejs'

Alpine.start()

// If you want Alpine's instance to be available everywhere.
window.Alpine = Alpine

